import React from 'react'

import Layout from "../components/layout";
import Navigation from '../components/navigation'
import { Box, Skeleton, Button, Switch, useColorMode, Icon, Stack } from "@chakra-ui/react";
import Container from '../components/container';
import axios from 'axios'
import SEO from "../components/seo"
import { isLoggedIn, clear_data, INIT_PROGRESS_BAR, getDomain, isElAble } from '../service'
import Header from '../components/header'
import { AiOutlineRight } from 'react-icons/ai'
import { Link } from 'gatsby'

const INIT_DATA = () => {
    if (typeof window !== 'undefined') {
        let el = localStorage.getItem('profile')
        if (el && el.length > 0) {
            return JSON.parse(el)
        } else {
            return {}
        }
    } else {
        return {}
    }
}


const Profile = () => {
    isLoggedIn()

    const [data, setData] = React.useState(INIT_DATA())
    const [isLogout, setisLogout] = React.useState(false);
    const [progressBar, setProgressBar] = React.useState(INIT_PROGRESS_BAR);

    React.useEffect(() => {
        console.log('render profile')

        if (!localStorage.getItem('profile')) {

            axios.get(`${isElAble() ? getDomain('el') : getDomain('po')}/webservice/rest/server.php`, {
                params: {
                    wstoken: JSON.parse(localStorage.getItem('d_elearning')).token,
                    wsfunction: 'core_webservice_get_site_info',
                    moodlewsrestformat: 'json'
                }
            }).then((response) => {
                // console.log(response.data)
                if (response.status === 200) {
                    setData(response.data)
                    localStorage.setItem('profile', JSON.stringify(response.data))
                }
            })
        } else {
            setData(JSON.parse(localStorage.getItem('profile')))
        }

    }, [])


    function _logout() {
        setisLogout(true)
        clear_data()
    }

    function _changeProgressBar() {
        setProgressBar(!progressBar)
        localStorage.setItem('show_progress_bar', !progressBar)
    }

    return (
        <Layout>
            <SEO title="Profile" />
            <Navigation active={2} />
            <Header title="Profile" />
            <Container>
                <Skeleton isLoaded={Object.keys(data).length > 0} fontSize='xl' fontWeight='bold' mb={3}>{data.firstname}</Skeleton>
                <Stack spacing={5}>
                    <Box width="100%" display="flex">
                        <Box w='100%'>Dark Mode</Box>
                        <SwitchColor />
                    </Box>
                    <Box width="100%" display="flex">
                        <Box w='100%'>Tampilkan Progress Bar</Box>
                        <Switch onChange={() => _changeProgressBar()} color="teal" isChecked={progressBar} />
                    </Box>
                    <Link to='/getstarted'>
                        <Box width="100%" display="flex">
                            <Box w='100%'>Tentang Aplikasi</Box>
                            <Icon as={AiOutlineRight} fill='gray.300' boxSize={5} />
                        </Box>
                    </Link>
                </Stack>
                <Button mt={10} isLoading={isLogout} onClick={() => _logout()} cursor="pointer" _hover={{ bg: 'dangerIndie' }} bg="danger" border="none" color="white" width="100%">Logout</Button>
            </Container>

            <Container>
                <Box mt={3} textAlign='center' fontSize={14}>
                    version 0.2.0
                </Box>
                <Box fontSize={14} textAlign="center"><a style={{ textDecoration: 'none' }} rel="noreferrer" target="_blank" href="https://www.instagram.com/keevnx/">App By Kevin Adam</a></Box>
            </Container>

        </Layout>
    )
}

function SwitchColor() {
    const { colorMode, toggleColorMode } = useColorMode();
    return (
        <Switch onChange={toggleColorMode} color="teal" isChecked={colorMode === "dark"} />
    );
}

export default Profile